import { GraphQLClient } from "graphql-request";
import { Outlet, redirect, useLoaderData } from "react-router-dom";
import { graphql } from "../gql/gql";
import LoginPage from "./LoginPage";

const isLoggedIn = graphql(/* GraphQL */ `
  query isLoggedIn {
    profile {
      id
      name
    }
  }
`);

export async function profileLoader() {
  let profile = null;

  const client = new GraphQLClient("/graphql", {
    credentials: "include",
    mode: "cors",
    redirect: "manual",
  });

  try {
    profile = await client.request({
      document: isLoggedIn,
    });
  } catch (error) {
    return redirect("/signin");
  }

  if (profile?.profile === null) {
    redirect("/signin");
  }

  return { profile: profile?.profile };
}

export default function ProtectedRoutes() {
  const { profile } = useLoaderData() as { profile?: { id: string } };

  return <>{profile ? <Outlet /> : <LoginPage />}</>;
}
