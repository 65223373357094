import { ReactNode } from "react";
import { Box, CssBaseline, Stack, ThemeProvider } from "@mui/material";
import { darkTheme, getLocalTheme, lightTheme } from "../theme";
import { GraphQLClient } from "graphql-request";
import { useQuery } from "@tanstack/react-query";
import { graphql } from "../gql/gql";
import { Theme } from "../gql/graphql";
import Layout from "./Layout";

type ScrollingLayoutProps = {
  showDrawer?: boolean;
  children: ReactNode;
  fullwidth?: boolean;
  theme?: "light" | "dark";
};

const getProfile = graphql(/* GraphQL */ `
  query profile {
    profile {
      id
      name
      picture
      email
      updatedAt
      locale
      theme
    }
  }
`);

const client = new GraphQLClient("/graphql", {
  credentials: "include",
  mode: "navigate",
  redirect: "error",
});

export default function ScrollingLayout(props: ScrollingLayoutProps) {
  const { data } = useQuery({
    queryKey: ["profile"],
    queryFn: async () => {
      try {
        return await client.request({
          document: getProfile,
        });
      } catch (error) {
        return { profile: null };
      }
    },
  });

  let theme = getLocalTheme() === "dark" ? darkTheme : lightTheme;

  if (props.theme === "dark" || data?.profile?.theme === Theme.Dark) {
    theme = darkTheme;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Stack
        sx={{ minHeight: "100vh" }}
        direction="column"
        justifyContent="space-between"
      >
        <Box>
          <Layout
            showDrawer={props.showDrawer}
            darkMode={theme === darkTheme}
            user={data?.profile}
          >
            <Box padding={props.fullwidth ? 0 : 1} mt="1rem">
              {props.children}
            </Box>
          </Layout>
        </Box>
      </Stack>
    </ThemeProvider>
  );
}
