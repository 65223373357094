"use client";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  export interface Theme {
    map: {
      style: string;
      circles: {
        border: string;
        fill: string;
      };
    };
    flightCard: {
      backgroundColor: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    map?: {
      style?: string;
      circles?: {
        border?: string;
        fill?: string;
      };
    };
    flightCard?: {
      backgroundColor?: string;
    };
  }
}

const darkTheme = createTheme({
  typography: {
    fontFamily: [
      '"Twemoji Country Flags"',
      '"Roboto"',
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  palette: {
    mode: "dark",
    background: {
      paper: "#111",
    },
  },
  map: {
    style:
      "filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);",
    circles: {
      fill: "#929292",
      border: "#929292",
    },
  },
  flightCard: {
    backgroundColor: "white",
  },
});

const lightTheme = createTheme({
  typography: {
    fontFamily: [
      '"Twemoji Country Flags"',
      '"Roboto"',
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  palette: {
    mode: "light",
  },
  map: {
    style: "",
    circles: {
      fill: "#717171",
      border: "#717171",
    },
  },
  flightCard: {
    backgroundColor: "#ededed",
  },
});

const getLocalTheme = (): string => {
  return localStorage.getItem("theme");
};

const setLocalTheme = (theme: "dark" | "light") => {
  localStorage.setItem("theme", theme);
};

export { darkTheme, lightTheme, getLocalTheme, setLocalTheme };
