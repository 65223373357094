import { Container, Grid, Link, TextField, Typography } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import ScrollingLayout from "../components/ScrollingLayout";
import ThemeSwitch from "../atoms/ThemeSwitch";
import { Theme, User } from "../gql/graphql";
import { graphql } from "../gql";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import request, { Variables } from "graphql-request";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import SnackbarAlert, { Alert } from "../atoms/SnackbarAlert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { setLocalTheme } from "../theme";

const updateUserSettings = graphql(/* GraphQL */ `
  mutation updateUserSettings($user: UpdateUserInput!) {
    updateUser(user: $user) {
      name
      simbrief
      theme
    }
  }
`);

export default function SettingsPage() {
  const { profile } = useLoaderData() as {
    profile?: { name: string; simbrief?: string; theme: string };
  };

  const queryClient = useQueryClient();
  const [currentProfile, setCurrentProfile] = useState<User>(profile as User);
  const [error, setError] = useState<Alert | null>(null);
  const [message, setMessage] = useState<Alert | null>(null);

  const updateUserSettingsMutation = useMutation({
    mutationFn: async (variables: Variables) =>
      request("/graphql", updateUserSettings, variables),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
      setCurrentProfile(data.updateUser as User);
      setMessage({ created: new Date(), message: "Settings saved." });
    },
    onError: (error) => {
      setError({
        created: new Date(),
        message: "Failed to update settings",
      });
    },
  });

  const handleSimbriefChange = useDebouncedCallback((value) => {
    updateUserSettingsMutation.mutate({
      user: { simbrief: !value ? null : value },
    });
  }, 300);

  const handleUsernameChange = useDebouncedCallback((value) => {
    updateUserSettingsMutation.mutate({
      user: { name: value },
    });
  }, 300);

  return (
    <ScrollingLayout>
      <SnackbarAlert
        created={error?.created}
        message={error?.message}
        type="error"
        onClose={() => setError(null)}
      />

      <SnackbarAlert
        created={message?.created}
        message={message?.message}
        type="success"
        onClose={() => setMessage(null)}
      />

      <Container>
        <Grid container spacing={2} mt={2} mb={4}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Settings
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6">Username</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Username"
              helperText=""
              value={currentProfile.name}
              onChange={(event) => {
                setCurrentProfile({
                  ...currentProfile,
                  name: event.target.value,
                });
                handleUsernameChange(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6">Simbrief username</Typography>
            <Typography variant="body2">or Pilot ID</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Simbrief username or Pilot ID"
              helperText={
                <>
                  <Typography variant="body2">
                    Used to synchronize your flights from Simbrief to
                    FlySimRoutes.{" "}
                    <strong>
                      We won't create or modify any data in your Simbrief
                      account.
                    </strong>{" "}
                    You can find your username as well as the Pilot ID in your{" "}
                    <Link
                      href="https://dispatch.simbrief.com/account"
                      target="_blank"
                      fontSize={14}
                    >
                      Simbrief account settings{" "}
                      <OpenInNewIcon sx={{ height: "16px" }} />
                    </Link>
                    .
                  </Typography>
                </>
              }
              value={currentProfile.simbrief}
              onChange={(event) => {
                setCurrentProfile({
                  ...currentProfile,
                  simbrief: event.target.value,
                });
                handleSimbriefChange(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6">Theme</Typography>
          </Grid>
          <Grid item xs={6}>
            <ThemeSwitch
              darkMode={currentProfile.theme === Theme.Dark}
              setDarkMode={function (darkMode: boolean): void {
                setLocalTheme(darkMode ? "dark" : "light");
                updateUserSettingsMutation.mutate({
                  user: { theme: darkMode ? Theme.Dark : Theme.Light },
                });
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </ScrollingLayout>
  );
}
